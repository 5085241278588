import { getCountryByPhoneCode } from '../../../../../apiRequests';

const getCountry = (phonecode, formik, t) => {
  if (phonecode !== formik.values.phoneCode.code) {
    getCountryByPhoneCode(phonecode)
      .then(({ data }) => {
        if (data.status === 'success') {
          formik.setFieldValue('phoneCode', {
            code: data.country.phoneCode,
            flag: data.country.flag,
          });
          formik.setFieldTouched('phoneCode');
        } else {
          formik.setFieldValue('phoneCode', { code: '', flag: '' }, false);
          formik.setFieldError('phoneCode', t('errors.phoneCodeError'));
        }
      })
      .catch((err) => console.error(err));
  }
};

export default getCountry;
