import React, { useState } from 'react';
import { StyledTooltip } from '../../LeftSection/Components/Title/Title.styled';
import Description from './components/Description';
import ItemIcon from './components/ItemIcon';
import { ItemTitle, LI, List } from './styles/AdditionalInformation.styled';
import { Link, Typography, useTheme } from '@mui/material';

const AdditionalInformation = ({
  additionalInformation,
  currencyCode,
  pickUpStation,
  setDialogOpen,
  apiType,
}) => {
  const deposit = additionalInformation && additionalInformation.deposit;
  const fuelPolicy = additionalInformation && additionalInformation.fuelPolicy;
  const policies = additionalInformation && additionalInformation.policies;
  const { direction } = useTheme();

  const price = deposit
    ? currencyCode !== undefined
      ? new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: currencyCode,
        }).format(deposit.price)
      : 0
    : 0;

  return (
    <>
      {deposit || fuelPolicy || policies ? (
        <List>
          {deposit && deposit.price && (
            <LI>
              <ItemIcon icon={'deposit'} />
              <StyledTooltip
                $direction={direction}
                $color="#000000DE"
                title={<Description description={deposit.description} />}
                arrow>
                <ItemTitle
                  style={{
                    fontSize: 13,
                    margin: `0 ${direction === 'ltr' ? 0 : '8px'} 0 ${
                      direction === 'ltr' ? '8px' : 0
                    }`,
                  }}
                  underline={
                    deposit.description !== '' ||
                    deposit.description !== undefined
                      ? 1
                      : 0
                  }>
                  {price} {deposit.name ? deposit.name.toLowerCase() : ''}
                </ItemTitle>
              </StyledTooltip>
            </LI>
          )}
          {fuelPolicy && (
            <LI>
              <ItemIcon icon={'fuelPolicy'} />
              <StyledTooltip
                $color="#000000DE"
                title={<Description description={fuelPolicy.description} />}
                arrow>
                <ItemTitle
                  style={{
                    margin: direction === 'ltr' ? '0 0 0 8px' : '0 8px 0 0',
                  }}
                  variant="body2"
                  underline={
                    fuelPolicy.description !== '' ||
                    fuelPolicy.description !== undefined
                      ? 1
                      : 0
                  }>
                  {fuelPolicy.name ? fuelPolicy.name : ''}
                </ItemTitle>
              </StyledTooltip>
            </LI>
          )}
          {policies &&
            policies.length > 0 &&
            policies.map((item, idx) => {
              const { description, name, code } = item;
              const underline =
                description === '' || description === undefined ? 0 : 1;
              return (
                <LI key={idx}>
                  <ItemIcon icon={item.policyType || item.code} code={code} />
                  {underline ? (
                    <StyledTooltip
                      $color="#000000DE"
                      title={<Description description={item.description} />}
                      arrow>
                      <ItemTitle
                        style={{
                          margin:
                            direction === 'ltr' ? '0 0 0 8px' : '0 8px 0 0',
                        }}
                        variant="body2"
                        underline={underline}>
                        {item.name ? item.name : ''}
                      </ItemTitle>
                    </StyledTooltip>
                  ) : (
                    <Typography sx={{ paddingLeft: 1 }}>{name}</Typography>
                  )}
                </LI>
              );
            })}
          {pickUpStation && (
            <LI>
              <ItemIcon icon={'location'} />
              <Link
                onClick={() =>
                  setDialogOpen({
                    status: true,
                    type: 'singleStationDescription',
                    payload: {
                      station: pickUpStation,
                      apiType,
                    },
                  })
                }
                variant="body2"
                sx={{
                  '&:hover': { cursor: 'pointer', fontWeight: 500 },
                  textUnderlineOffset: '4px',
                  margin: direction === 'ltr' ? '0 0 0 8px' : '0 8px 0 0',
                  color: 'rgba(0, 0, 0, 0.87)',
                  fontWeight: 400,
                }}>
                {pickUpStation.title}
              </Link>
            </LI>
          )}
        </List>
      ) : (
        ''
      )}
    </>
  );
};

export default AdditionalInformation;
