import React, { useState, useContext, useEffect, lazy } from 'react';
import {
  Grid,
  Divider,
  useMediaQuery,
  useTheme,
  Step,
  StepButton,
  Hidden,
  Backdrop,
} from '@mui/material';
import Preloader from '../../Layouts/Preloader/Preloader';
import axios from 'axios';
import Picker from '../../Layouts/Picker/Picker';
import SearchResults from './SearchResults';
import AddInsurance from './Components/AddInsurance/AddInsurance';
import OrderSummary from './OrderSummary/OrderSummary';
import PageNotFound from '../404/PageNotFound';
import { CurrencyContext } from '../../SharedState';
import { useTranslation } from 'react-i18next';
import OrderConfirmation from './Steps/OrderConfirmation';
import { getExtrasPricesUrl, getFetchCarsUrl } from './utils';
import { SEOdata } from '../../../utils/';
import {
  AgentContext,
  SearchDetailsContext,
} from '../../../components/SharedState/SearchState';
import { useParams } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  getOneWayFee,
  getPathNameByStep,
  getStepByParam,
  getSteps,
  processExtras,
  processInsurances,
  removeDuplicates,
} from './utils/utils';

import { LoadingSkeletonCards } from './Components';
import { LeftPane } from '../../ReusableComponents';
import ErrorServerResponseDialog from './Components/Dialogs/ErrorServerResponseDialog';
import PaymentPage from './Components/PaymentPage';
import validateAndSendEmail from './utils/validateAndSendEmail';
import {
  getTotalUserPrice,
  getTypeOfGearBoxBySippCode,
} from '../../../utils/utils';
import SortAndFiltersPanel from './Components/SortAndFiltersPanel/SortAndFiltersPanel';
import { StyledContainer, StyledStepper } from './styles/Order.styled';
import Filters from './LeftPane/Filters/Filters';
import FiltersDialog from './Components/Dialogs/FiltersDialog';
import SearchAnimation from '../../Layouts/Preloader/SearchAnimation/SearchAnimation';
import { minMax } from './LeftPane/Filters/utils';
import OrderSummarySection from './LeftPane/Components/PaymentDetails/Components/OrderSummarySection/OrderSummarySection';
import CommonDialog from './Components/Dialogs/CommonDialog';
const SessionExpiredDialog = lazy(() =>
  import('./Components/Dialogs/SessionExpiredDialog')
);

const Order = () => {
  const { search } = useLocation();
  const [searchDetails, setSearchDetails] = useContext(SearchDetailsContext);
  const query = new URLSearchParams(search);
  const { pickUpDate, dropOffDate, driverAge, country } = searchDetails;
  const {
    t,
    i18n: {
      languages: [lng],
    },
  } = useTranslation();
  const { LocationName: pickUpLocation, LocationCode: pickUpLocationCode } =
    searchDetails.pickUpLocation;

  /**
   * State section
   */
  const [personalDetails, setPersonalDetails] = useState({});
  const [cars, setCars] = useState([]);
  const [suppliersList, setSuppliersList] = useState([]);
  const [filteredCars, setFilteredCars] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [currency, setCurrency] = useContext(CurrencyContext);
  const [agent] = useContext(AgentContext);
  const [isLoading, setIsLoading] = useState(true);
  const [searchLoading, setSearchLoading] = useState(false);

  const [dialogOpen, setDialogOpen] = useState({
    status: false,
    type: '',
    payload: {},
  });
  const [requestedCar, setRequestedCar] = useState(
    JSON.parse(sessionStorage.getItem('requestedCar')) || {}
  );
  const [userOrder, setUserOrder] = useState(
    JSON.parse(sessionStorage.getItem('userOrder')) || {}
  );

  const [carsClasses, setCarsClasses] = useState([]);
  const [gearBoxes, setGearBoxes] = useState([]);
  const [fuelTypes, setFuelTypes] = useState([]);

  const [sessionExpired, setSessionExpired] = useState({
    open: false,
    dialogTitle: '',
    dialogContent: '',
  });
  const [filterDialogOpen, setFilteDialogOpen] = useState(false);
  const [MileageChecked, setMileageChecked] = useState({
    Limited: false,
    Unlimited: false,
  });
  // useEffect(() => {
  //   console.log(searchDetails);
  // }, [searchDetails]);
  // useEffect(() => {
  //   console.log('currency', currency);
  // }, [currency]);
  setTimeout(() => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    } else {
      setSessionExpired({ ...sessionExpired, open: true });
    }
  }, 570000); // 9:30 minutes

  // Car groups filter
  const [checkState, setCheckState] = useState({});
  const [value, setValue] = useState(minMax(cars));

  const [isAscending, setIsAscending] = useState(true);
  const [errorResponse, setErrorResponse] = useState({
    value: false, //open dialog when true
    message: '',
    stayOnPage: false,
  });
  const [additionalOptions, setAdditionalOptions] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [extrasPrices, setExtrasPrices] = useState(
    JSON.parse(sessionStorage.getItem('extrasPrices'))
  );
  const [policies, setPolicies] = useState([]);

  // useEffect(() => {
  //   console.log('TOTAL', totalPrice);
  //   // console.log('ORDER', userOrder);
  // }, [totalPrice, userOrder]);

  useEffect(() => {
    if (cars.length > 0) {
      setValue(minMax(cars));
    }
  }, [cars]);

  /**
   * Filtering
   */
  useEffect(() => {
    const filteredClasses = carsClasses
      .filter((cl) => cl.isChecked)
      .map((item) => {
        if (item.type === 'e') {
          return 'e';
        }
        return item.carClass;
      });
    const filterGearBoxes = gearBoxes
      .filter((gb) => gb.isChecked)
      .map((item) => {
        return item.type;
      });
    const filteredFuelTypes = fuelTypes
      .filter((ft) => ft.isChecked)
      .map((item) => item.type);

    const filteredSuppliers = suppliersList
      .filter((s) => s.checked)
      .map((item) => item.supplier);

    let carsToFilter = cars;

    if (filteredClasses.length > 0) {
      carsToFilter = carsToFilter.filter((car) => {
        const idx = filteredClasses.findIndex((cl) => {
          if (cl === 'e') {
            return car.sipp_code[3] === 'E';
          }
          return car.carClass === cl;
        });
        return idx > -1;
      });
    }
    if (filterGearBoxes.length > 0) {
      carsToFilter = carsToFilter.filter((car) => {
        const idx = filterGearBoxes.findIndex((gb) => car.typeOfGearBox === gb);
        return idx > -1;
      });
    }
    if (filteredFuelTypes.length > 0) {
      carsToFilter = carsToFilter.filter((car) => {
        const idx = filteredFuelTypes.findIndex((ft) => car.fuel === ft);
        return idx > -1;
      });
    }
    if (filteredSuppliers.length > 0) {
      carsToFilter = carsToFilter.filter((car) => {
        const idx = filteredSuppliers.findIndex((s) => car.supplier.name === s);
        return idx > -1;
      });
    }

    // const { Unlimited } = MileageChecked;
    // let tempCars = [];

    // if (Unlimited) {
    //   tempCars = carsToFilter.filter(car => (car.mileage ? car.mileage.isUnlimitedMileage === true : true));
    // }

    setFilteredCars(carsToFilter);
  }, [gearBoxes, MileageChecked, carsClasses, cars, suppliersList, fuelTypes]);

  // useEffect(() => {
  //   console.log('Cars', cars, 'filteredCars', filteredCars);
  // }, [cars, filteredCars]);
  const handlePriceRangeChange = ([min, max]) => {
    setIsLoading(true);

    setTimeout(() => {
      const priceRangeCars = cars.filter(
        (car) =>
          car.basePrice.totalBasePrice > min &&
          car.basePrice.totalBasePrice < max
      );
      setFilteredCars(priceRangeCars);
      setIsLoading(false);
    }, 2000);
  };
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    const step = getStepByParam(params.step);
    if (step !== activeStep) {
      setActiveStep(step);
    }
  }, [params]);
  // useEffect(() => {
  //   console.log(requestedCar);
  // }, [requestedCar]);
  // useEffect(() => {
  //   console.log(userOrder);
  // }, [userOrder]);
  useEffect(() => {
    const fetchCars = async () => {
      const url = getFetchCarsUrl(searchDetails, query, lng, currency.code);
      try {
        // setIsLoading(true);
        setSearchLoading(true);

        const {
          data: { cars: fetchedCars, failureReason, currency },
        } = await axios.get(url);
        if (fetchedCars && fetchedCars.length > 0) {
          setCars(fetchedCars);

          let classes = fetchedCars.map((car) => ({
            carClass: car.carClass,
            isChecked: false,
            image: car.image,
          }));
          if (currency) {
            setCurrency({
              code: currency,
            });
          }

          const electricOption = fetchedCars.find((car) => {
            return car.sipp_code[3] === 'E';
          });

          // const carsFeatures = fetchedCars.map((car) => {
          //   const fuel = capitalize(
          //     getFuelTypeAndACBySippCode(car.sipp_code, t).fuel
          //   );
          //   if (!car.fuel) car.fuel = fuel;
          //   else car.fuel = capitalize(car.fuel);
          //   return { fuel };
          // });
          // console.log("FUEL", carsFeatures)
          const fuelTypes = [
            ...new Set(fetchedCars.map(({ fuel }) => fuel)),
          ].filter((f) => f !== '');
          setFuelTypes(fuelTypes.map((ft) => ({ type: ft, checked: false })));

          const filteredClasses = classes.filter((cc, idx, arr) => {
            return idx === arr.findIndex((t) => t.carClass === cc.carClass);
          });
          if (electricOption) {
            filteredClasses.push({
              carClass: t('carclasses.electric'),
              isChecked: false,
              image: electricOption.image,
              type: 'e',
            });
          }
          setCarsClasses(filteredClasses);
          const typesofgearboxes = [
            ...new Set(
              fetchedCars.map((car) => {
                const gb = getTypeOfGearBoxBySippCode(car.sipp_code, lng);
                car.typeOfGearBox = gb;
                return gb;
              })
            ),
          ];
          // console.log('GEARBOXES ARE', typesofgearboxes);
          setGearBoxes(
            typesofgearboxes.map((gb) => ({ type: gb, isChecked: false }))
          );
        } else {
          setErrorResponse({
            value: true,
            message: failureReason.message || failureReason,
          });
        }
      } catch (err) {
        console.log(err);
        err.response
          ? setErrorResponse({
              value: true,
              message:
                (err.response.data && err.response.data.message) ||
                t('errors.tryAnotherDays'),
            })
          : setErrorResponse({ value: true, message: 'Network problem' });
      } finally {
        setIsLoading(false);
        setSearchLoading(false);
      }
    };
    if (
      searchDetails.pickUpLocation.LocationCode &&
      searchDetails.dropOffLocation.LocationCode
    ) {
      fetchCars();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDetails]);

  useEffect(() => {
    setSuppliersList(
      removeDuplicates(
        cars.map((car) => {
          return { supplier: car.supplier.name, checked: false };
        })
      )
    );
  }, [cars]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const ispc = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    const { apiType, paymentInformation, isPaymentRequired, prices } =
      requestedCar;

    if (
      (requestedCar &&
        paymentInformation &&
        isPaymentRequired &&
        totalPrice === 0) ||
      apiType === 4
    ) {
      setTotalPrice(Number(paymentInformation.amount.toFixed(2)));
    }

    if (
      requestedCar &&
      prices &&
      userOrder.insurances &&
      extrasPrices &&
      apiType !== 4
    ) {
      setTotalPrice(
        getTotalUserPrice(
          userOrder,
          extrasPrices,
          prices.protection_coverage.saturdayInsurance,
          country,
          false,
          lng === 'he' ? true : false,
          additionalOptions,
          prices.vatLevel
        )
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalOptions, userOrder, extrasPrices, requestedCar, country, lng]);

  const steps = getSteps(
    isMobile,
    t,
    requestedCar.paymentInformation &&
      requestedCar.paymentInformation.isPaymentRequired
  );
  const handleSaveNewOrder = async (driverDetails, sendEmail, uuid, isLead) => {
    const orderData = {
      uuid,
      extrasPrices,
      searchDetails,
      userOrder,
      currencyCode: currency.code,
      personalDetails: driverDetails,
      requestedCar,
      additionalOptions,
      totalPrice,
    };

    try {
      const {
        data: { totalPrice },
      } = await validateAndSendEmail({
        orderData,
        setIsLoading,
        lng,
        t,
        sendEmail,
        agent,
        isLead,
      });
      if (!isLead) {
        setIsLoading(false);
        if (totalPrice) {
          setTotalPrice(totalPrice);
        }
        handleNext();
      }
    } catch (err) {
      console.error(err.response.data);
      if (!isLead) {
        setIsLoading(false);
        setErrorResponse({
          value: true,
          message: err.response.data.message,
          stayOnPage: true,
        });
      }
    }
  };

  /**
   *
   * @param {Object} carId
   */
  const handleBookButtonClicked = async (carId) => {
    const selectedCar = cars.find((car) => car.carId === carId);
    if (selectedCar && !searchDetails.country.code) {
      setSearchDetails({
        ...searchDetails,
        country: {
          code: selectedCar.country.code,
          name: selectedCar.country.name,
        },
      });
    }
    setRequestedCar(selectedCar);
    sessionStorage.setItem('requestedCar', JSON.stringify(selectedCar));
    //fetch prices

    const url = getExtrasPricesUrl(selectedCar, searchDetails, lng, query);
    let prices = {};
    try {
      const { data } = await axios.get(url);
      if (data.policies) {
        setPolicies(data.policies);
      }
      const code = data.currencyCode
        ? data.currencyCode
        : selectedCar.currencyCode;

      if (code !== currency.code) {
        setCurrency({ code });
      }

      prices = processExtras(
        data.extras,
        driverAge,
        selectedCar.supplier,
        country,
        searchDetails.pickUpLocation,
        searchDetails.dropOffLocation,
        pickUpDate,
        dropOffDate,
        selectedCar.extras
      );
      let insurances;
      if (selectedCar.apiType === 4) {
        // insurances = data.insurances;
        // insurances.forEach((insurance) => {
        //   insurance.priceWithoutInsurance =
        //     selectedCar.paymentInformation.amount;
        // });
        insurances = selectedCar.prices.protection_coverage
          ? selectedCar.prices.protection_coverage
          : [];
      } else {
        insurances = processInsurances(
          selectedCar.prices.protection_coverage,
          data.insurancesDescriptions
        );
      }

      const { numberOfFreeDays, numberOfDays } = selectedCar.basePrice;
      const tempUserOrder = {
        ...userOrder,
        insurances,
        carBasePrice: selectedCar.basePrice.price,
        totalBasePrice: selectedCar.basePrice.totalBaseDisplayBasePrice,
        totalNetAmount: selectedCar.basePrice.totalBasePrice,
        numberOfFreeDays,
        numberOfDays,

        oneWayFee: getOneWayFee(userOrder, requestedCar, prices),
      };
      setUserOrder(tempUserOrder);
      sessionStorage.setItem('userOrder', JSON.stringify(tempUserOrder));
      setExtrasPrices(prices);
      sessionStorage.setItem('extrasPrices', JSON.stringify(prices));
      const addOpts = data.additionalProducts || [];

      setAdditionalOptions(addOpts);
      sessionStorage.setItem('additionalOptions', addOpts);

      // setIsLoading(false);
      handleNext();
    } catch (err) {
      if (err.response && err.response.status === 422) {
        const title = err.response.data.message.title;
        const content = err.response.data.message.content;
        if (title && content) {
          setSessionExpired({
            open: true,
            dialogTitle: title,
            dialogContent: content,
          });
        } else {
          setErrorResponse({ value: true, message: err.response.data.message });
        }
      } else console.log(err);
      console.error(err);
    }
  };
  // useEffect(() => {
  //   console.log('Extras FINAL: ', extrasPrices);
  // }, [extrasPrices]);

  const handleSortByPrice = () => {
    setIsLoading(true);
    setTimeout(() => {
      const arrayOfCars = filteredCars.length > 0 ? filteredCars : cars;
      if (isAscending) {
        arrayOfCars.sort((car1, car2) => {
          const price1 = car1.basePrice.price || car1.basePrice.pricePerPeriod;
          const price2 = car2.basePrice.price || car2.basePrice.pricePerPeriod;
          return price2 - price1;
        });
        setIsAscending(false);
      } else {
        arrayOfCars.sort((car1, car2) => {
          const price1 = car1.basePrice.price || car1.basePrice.pricePerPeriod;
          const price2 = car2.basePrice.price || car2.basePrice.pricePerPeriod;
          return price1 - price2;
        });
        setIsAscending(true);
      }
      setIsLoading(false);
    }, 1000);
  };

  const getStepContent = (step) => {
    if (step !== 0 && !requestedCar) {
      handleStep(0);
    }
    switch (step) {
      case 0:
        return cars.length === 0 || isLoading ? (
          <LoadingSkeletonCards />
        ) : (
          <>
            {
              <SortAndFiltersPanel
                setFilteDialogOpen={setFilteDialogOpen}
                isMobile={isMobile}
                gearBoxes={gearBoxes}
                fuelTypes={fuelTypes}
                setGearBoxes={setGearBoxes}
                carsClasses={carsClasses}
                setCarsClasses={setCarsClasses}
                checkState={checkState}
                setCheckState={setCheckState}
                MileageChecked={MileageChecked}
                setMileageChecked={setMileageChecked}
                handleSortByPrice={handleSortByPrice}
                t={t}
              />
            }
            <SearchResults
              setDialogOpen={setDialogOpen}
              numberOfDays={userOrder.numberOfDays}
              carLocation={pickUpLocation[lng]}
              isLoading={isLoading}
              searchResult={filteredCars.length !== 0 ? filteredCars : cars}
              handleBookButtonClicked={handleBookButtonClicked}
              country={country}
            />
          </>
        );

      case 1:
        return (
          <AddInsurance
            setAdditionalOptions={setAdditionalOptions}
            additionalOptions={additionalOptions}
            handleNext={handleNext}
            handleBack={handleBack}
            currencyCode={currency.code}
            searchDetails={searchDetails}
            requestedCar={requestedCar}
            setRequestedCar={setRequestedCar}
            extrasPrices={extrasPrices}
            setExtrasPrices={setExtrasPrices}
            userOrder={userOrder}
            setUserOrder={setUserOrder}
            totalPrice={totalPrice}
            setTotalPrice={setTotalPrice}
          />
        );
      case 2:
        return (
          <OrderSummary
            handleSaveNewOrder={handleSaveNewOrder}
            currencyCode={currency.code}
            handleNext={handleNext}
            isLoading={isLoading}
            setPersonalDetails={setPersonalDetails}
            personalDetails={personalDetails}
            handleBack={handleBack}
            extrasPrices={extrasPrices}
            requestedCar={requestedCar}
            searchDetails={searchDetails}
            userOrder={userOrder}
          />
        );
      case 3: {
        /** */
        return requestedCar.paymentInformation &&
          requestedCar.paymentInformation.isPaymentRequired ? (
          <PaymentPage
            currencyCode={currency.code}
            personalDetails={personalDetails}
            requestedCar={requestedCar}
            handleNext={handleNext}
            handleBack={handleBack}
            totalPrice={totalPrice}
          />
        ) : (
          <OrderConfirmation />
        );
      }
      case 4:
        return requestedCar.paymentInformation &&
          requestedCar.paymentInformation.isPaymentRequired ? (
          <OrderConfirmation />
        ) : null;
      default:
        return <PageNotFound />;
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    navigate(`/search/${getPathNameByStep(activeStep + 1)}${search}`);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    navigate(`/search/${getPathNameByStep(activeStep - 1)}${search}}`);
  };

  const handleStep = (step) => {
    if (activeStep !== 3 && step !== 3 && activeStep !== 0 && requestedCar) {
      setActiveStep(step);
      navigate(`/search/${getPathNameByStep(step)}${search}`);
    } else {
      setActiveStep(0);
      navigate(`/search/${getPathNameByStep(0)}${search}}`);
    }
  };

  return (
    <div style={{ backgroundColor: '#f5f5f5' }}>
      <Backdrop open={isLoading || searchLoading} style={{ zIndex: 1200 }}>
        {searchLoading ? (
          <SearchAnimation
            isMobile={isMobile}
            country={searchDetails.country.code}
          />
        ) : (
          <Preloader />
        )}
      </Backdrop>

      <SEOdata
        lang={lng}
        title={
          t('metatags.rentACarIn') + query.get('locationname') ||
          searchDetails.pickUpLocation.LocationName[lng]
        }
        description={t('metatags.searchPage.description', {
          location:
            query.get('locationname') ||
            searchDetails.pickUpLocation.LocationName[lng],
        })}
      />
      <StyledContainer ismobile={isMobile.toString()}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm
            style={{ marginBottom: '8px', borderRadius: '4px' }}>
            <StyledStepper
              nonLinear
              activeStep={activeStep}
              alternativeLabel={isMobile}
              circlecolor={theme.palette.secondary.main}
              ismobile={isMobile ? 1 : 0}>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepButton onClick={() => handleStep(index)}>
                    {label}
                  </StepButton>
                </Step>
              ))}
            </StyledStepper>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          {(activeStep !== 3 ||
            (requestedCar.paymentInformation &&
              requestedCar.paymentInformation.amount !== 0)) && (
            <Hidden smDown>
              <Grid item md={4} xs={12} style={{ marginBottom: 16 }}>
                <LeftPane ispc={ispc}>
                  {(activeStep !== 3 ||
                    requestedCar.paymentInformation === 0) && (
                    <>
                      <Picker
                        handleStep={handleStep}
                        isMain={false}
                        isLoading={isLoading}
                        pickUpLocation={query.get('pl')}
                        dropOffLocation={query.get('dl')}
                      />
                      <Divider />
                    </>
                  )}
                  {activeStep === 0 && !isLoading && checkState ? (
                    <Filters
                      value={value}
                      setValue={setValue}
                      handleFinalPriceRangeChange={handlePriceRangeChange}
                      setIsLoading={setIsLoading}
                      currency={currency}
                      gearBoxes={gearBoxes}
                      setGearBoxes={setGearBoxes}
                      fuelTypes={fuelTypes}
                      setFuelTypes={setFuelTypes}
                      isMobile={isMobile}
                      carsClasses={carsClasses}
                      setCarsClasses={setCarsClasses}
                      cars={cars}
                      filteredCars={{ filteredCars, setFilteredCars }}
                      checkState={checkState}
                      setCheckState={setCheckState}
                      suppliers={{ suppliersList, setSuppliersList }}
                      mileage={{ MileageChecked, setMileageChecked }}
                    />
                  ) : null}
                  {/**PC & Tablet version only */}
                  {requestedCar && (
                    <Hidden smDown>
                      {(activeStep === 1 ||
                        activeStep === 2 ||
                        activeStep === 3) &&
                        requestedCar.prices && (
                          <OrderSummarySection
                            policies={policies}
                            requestedCar={requestedCar}
                            userOrder={userOrder}
                            totalPrice={totalPrice}
                            additionalOptions={additionalOptions}
                            extrasPrices={extrasPrices}
                          />
                        )}
                    </Hidden>
                  )}
                </LeftPane>
              </Grid>
            </Hidden>
          )}
          <Grid
            item
            md={
              activeStep === 3 &&
              requestedCar.paymentInformation &&
              requestedCar.paymentInformation.amount === 0
                ? 12
                : 8
            }
            xs={12}>
            {getStepContent(activeStep)}
          </Grid>
        </Grid>
        <ErrorServerResponseDialog
          setErrorResponse={setErrorResponse}
          errorResponse={errorResponse}
        />
      </StyledContainer>
      <SessionExpiredDialog
        t={t}
        open={sessionExpired.open}
        content={sessionExpired.dialogContent}
        title={sessionExpired.dialogTitle}
      />
      <FiltersDialog
        value={value}
        setValue={setValue}
        handleFinalPriceRangeChange={handlePriceRangeChange}
        setIsLoading={setIsLoading}
        currency={currency}
        open={filterDialogOpen}
        setOpen={setFilteDialogOpen}
        gearBoxes={gearBoxes}
        setGearBoxes={setGearBoxes}
        isMobile={isMobile}
        carsClasses={carsClasses}
        setCarsClasses={setCarsClasses}
        cars={cars}
        filteredCars={{ filteredCars, setFilteredCars }}
        checkState={checkState}
        setCheckState={setCheckState}
        suppliers={{ suppliersList, setSuppliersList }}
        mileage={{ MileageChecked, setMileageChecked }}
      />
      <CommonDialog dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />
    </div>
  );
};

export default Order;
