import React from 'react';
import { Country, LocationTitle } from '../styles/Option.styled';

const OptionName = ({ name, country, direction }) => {
  return (
    <div>
      <LocationTitle dir={direction}>{name}</LocationTitle>
      <Country dir={direction} $direction={direction}>
        {country}
      </Country>
    </div>
  );
};

export default OptionName;
