import { MenuItem, TextField } from '@mui/material';
import React from 'react';
import { GreenBorderTextField } from '../../../../../ReusableComponents/StyledComponents';

const Title = ({ handleChange, title, language, label, direction }) => {
  const items = {
    en: [
      { value: 'Mr', name: 'Mr' },
      { value: 'Mrs', name: 'Mrs' },
      { value: 'Ms', name: 'Ms' },
      { value: 'Dr', name: 'Dr' },
      { value: 'Prof', name: 'Prof' },
    ],
    ru: [
      { value: 'Mr', name: 'Г-н' },
      { value: 'Ms', name: 'Г-жа' },
    ],
    he: [
      { value: 'Mr', name: 'מר' },
      { value: 'Ms', name: 'גב' },
      { value: 'Dr', name: 'דר' },
    ],
  };

  return (
    <GreenBorderTextField
      $direction={direction}
      variant="outlined"
      fullWidth
      id="title"
      select
      label={label}
      name="title"
      value={title}
      onChange={handleChange}>
      {items[language].map((item, idx) => (
        <MenuItem key={idx} value={item.value}>
          {item.name}
        </MenuItem>
      ))}
    </GreenBorderTextField>
  );
};

export default Title;
