const name = {
  en: 'We4rent | Cheap car rental worldwide',
  ru: 'We4rent | Дешёвая аренда авто по всему миру',
  he: 'השכרת רכבים זולה בישראל ובכל העולם',
};
const description = {
  en: 'Cheap car rental all over the world',
  ru: 'Индивидуальный подход к каждому клиенту 🤝, поддержка и обслуживание на русском языке 24/7 🕒, большой выбор компаний и машин 🚗. Бронируйте авто легко и быстро!',
  he: `We4Rent: השכרת רכב בישראל - בקלות, במהירות ובמחירים משתלמים! הזמינו עכשיו +972-54-2041614`,
};
const streetAddress = {
  en: 'Karl Netter',
  ru: 'Карл Неттер',
  he: 'קרל נטר',
};

const addressLocality = {
  en: 'Rishon Le Zion',
  ru: 'Ришон ле Цион',
  he: 'ראשון לציון',
};

const localBusinessSchema = (lng) => {
  const schema = {
    '@context': 'http://www.schema.org',
    '@type': 'OnlineBusiness',
    url: 'https://www.we4rent.com',
    name: name[lng],
    logo: 'https://www.we4rent.com/icons/favicon/ms-icon-310x310.png',
    image: 'https://www.we4rent.com/images/cover.jpg',
    priceRange: '$',
    telephone: '+972-54-2041614',
    description: description[lng],
    address: {
      '@type': 'PostalAddress',
      streetAddress: streetAddress[lng],
      addressLocality: addressLocality[lng],
      addressCountry: 'Israel',
      contactType: 'website',
    },
    paymentAccepted: 'Credit Card',
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: '+972 (54) 2041614',
      email: 'contact@we4rent.com',
      contactType: 'Customer service',
    },
    knowsLanguage: ['RU', 'EN', 'HE'],
    areaServed: 'Worldwide',
    legalName: 'We4travel',
    sameAs: [
      'https://g.page/r/CdVH3Cu655POEBM',
      'https://www.facebook.com/we4rent/',
      'https://t.me/we4rent',
      'https://wa.me/972542041614',
    ],
    foundingDate: '2020-04-20',
    foundingLocation: 'Rishon LeZion, Israel',
  };
  return JSON.stringify(schema);
};

export default localBusinessSchema;
