import React from 'react';
import {
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  getTotalUserPrice,
  nameMapping,
  displayPriceWithCurrency,
} from '../../../../../../utils';
import { DottedText } from './Components/helpers';
import CustomRow from './Components/CustomRow';
import ExtrasRow from './Components/ExtrasRow';
import FeesRow from './Components/FeesRow';
import { useTheme } from '@mui/styles';

const UserOrderSummary = ({
  userOrder,
  extrasPrices,
  saturdayInsurance,
  amountToPay,
  additionalOptions,
  totalPrice,
  currency,
  requestedCar,
}) => {
  const {
    t,
    i18n: { languages },
  } = useTranslation();
  const { direction } = useTheme();

  const styles = {
    tableHead: {
      textAlign: direction === 'rtl' ? 'right' : 'left',
      fontWeight: languages[0] === 'ru' ? 600 : null,
    },
  };

  const {
    country,
    prices: { vatLevel, fees },
    basePrice: { VATIncluded },
  } = requestedCar;
  const { numberOfDays, numberOfFreeDays } = userOrder;

  const OrderRow = ({ orderItem, name }) => {
    let price = 0;
    if (orderItem === 'numberOfFreeDays' && languages[0] === 'he') {
      name = 'saturdayInsurance';
      price = numberOfFreeDays * saturdayInsurance;
    } else if (orderItem === 'carBasePrice' && languages[0] === 'he') {
      price = +(userOrder.carBasePrice * numberOfDays).toFixed(2);
    } else {
      price = +(
        userOrder[orderItem] *
        (name === 'oneWayFee' || name === 'totalNetAmount' ? 1 : numberOfDays)
      ).toFixed(2);
    }
    const notRender =
      orderItem === 'numberOfDays' ||
      name === 'numberOfFreeDays' ||
      orderItem === 'carBasePrice' ||
      (price === 0 &&
        (orderItem === 'oneWayFee' ||
          orderItem === 'airportFee' ||
          orderItem === 'superCDW' ||
          orderItem === 'superTP'));

    return (
      !notRender && (
        <TableRow>
          <TableCell
            component="th"
            scope="row"
            align={direction === 'ltr' ? 'left' : 'right'}>
            {nameMapping(name, t)}
          </TableCell>
          <TableCell align="right">
            {price === 0 &&
            (name === 'CDW' || name === 'TP' || name === 'three_PLC')
              ? t('common.included')
              : `${displayPriceWithCurrency('', price, currency)}`}
          </TableCell>
        </TableRow>
      )
    );
  };

  return (
    <>
      <Typography variant="h6" style={{ marginTop: '16px' }}>
        {t('common.yourOrder')}
      </Typography>

      <TableContainer
        component={Paper}
        variant="outlined"
        elevation={0}
        style={{ margin: '16px 0' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2" style={{ ...styles.tableHead }}>
                  {t('common.orderDetails')}
                </Typography>
              </TableCell>
              <TableCell
                style={{ fontWeight: languages[0] === 'ru' ? 600 : null }}
                align="right">
                {t('common.pricePer')}{' '}
                {t('common.perdays.days', { count: numberOfDays })}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(userOrder).map(([key, value]) => {
              return (
                value !== undefined &&
                key !== 'insurances' &&
                (key !== 'numberOfFreeDays' ||
                  (key === 'numberOfFreeDays' && value > 0)) && (
                  <OrderRow key={key} orderItem={key} name={key} />
                )
              );
            })}
            {userOrder.insurances &&
              userOrder.insurances
                .filter((insurance) => {
                  return insurance.isChecked;
                })
                .map((insurance) => {
                  return (
                    <ExtrasRow
                      direction={direction}
                      numberOfDays={numberOfDays}
                      currency={currency}
                      key={insurance.code}
                      name={insurance.name}
                      description={insurance.orderSummaryDescription}
                      orderItem={insurance}
                      isInsurance={true}
                    />
                  );
                })}
            {extrasPrices !== undefined &&
              extrasPrices.map((extra) => {
                // console.log(extra);
                return (
                  extra.isChecked &&
                  extra.price !== 0 && (
                    <ExtrasRow
                      direction={direction}
                      currency={currency}
                      key={extra.code}
                      orderItem={extra}
                      numberOfDays={numberOfDays}
                      description={extra.orderSummaryDescription}
                    />
                  )
                );
              })}
            {/**Additional options row*/}

            {additionalOptions &&
              additionalOptions.map((option) => {
                return (
                  option.isChecked && (
                    <CustomRow
                      currency={currency}
                      key={option.productId}
                      value={option.totalPrice}
                      name={option.name}
                    />
                  )
                );
              })}
            {/**
             * Fees row
             */}

            {fees && <FeesRow fees={fees} currency={currency} />}

            {/**End fees row */}

            {/**DISCOUNT ROW */}
            {numberOfFreeDays > 0 && (
              <CustomRow
                currency={currency}
                name="discount"
                value={-numberOfFreeDays * userOrder.carBasePrice}
              />
            )}

            {/**VAT ROW */}
            {VATIncluded === false && (
              <CustomRow
                currency={currency}
                name="vat"
                value={getTotalUserPrice(
                  userOrder,
                  extrasPrices,
                  saturdayInsurance,
                  country,
                  true,
                  false,
                  additionalOptions,
                  vatLevel
                )}
              />
            )}
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                align={direction === 'rtl' ? 'right' : 'left'}>
                <Typography variant="h6">{t('common.total')}</Typography>{' '}
              </TableCell>
              <TableCell align="right">
                <Typography variant="h6">
                  {displayPriceWithCurrency('', totalPrice, currency)}
                </Typography>
              </TableCell>
            </TableRow>
            {/**
             * Row with payment amount
             */}
          </TableBody>
        </Table>
      </TableContainer>

      {amountToPay > 0 && amountToPay < totalPrice && (
        <TableContainer component={Paper}>
          <Table style={{ marginTop: 8 }}>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Tooltip title={t('common.payNow.tip')}>
                    <DottedText>{t('common.payNow.payNow')}</DottedText>
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  {displayPriceWithCurrency('', amountToPay, currency)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Tooltip title={t('common.remainedAmount.tip')}>
                    <DottedText>
                      {t('common.remainedAmount.remainedAmount')}
                    </DottedText>
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  {displayPriceWithCurrency(
                    '',
                    totalPrice - amountToPay,
                    currency
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default UserOrderSummary;
