import { TextField, Tooltip } from '@mui/material';
import styled from 'styled-components';

export const LeftPane = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: ${(props) => (props.ispc ? '490px' : '100%')};
  height: 100%;
`;

export const GreenBorderTextField = styled(TextField)`
  & .MuiFormHelperText-root {
    .Mui-error {
      color: yellow;
      text-align: ${({ $direction }) =>
        $direction === 'rtl' ? 'right !important' : 'left !important'};
    }
  }
  .MuiFormLabel-root {
    transform-origin: ${({ $direction }) =>
      $direction === 'rtl' ? 'right !important' : 'left !important'};
    left: inherit !important;
    right: ${({ $direction }) =>
      $direction === 'rtl' ? '1.75rem !important' : 'inherit'};
    /* overflow: unset; */
  }
  & .MuiOutlinedInput-root {
    .MuiOutlinedInput-notchedOutline {
      legend {
        text-align: ${({ $direction }) =>
          $direction === 'rtl' ? 'right !important' : 'left !important'};
        /* text-align: right !important; */
      }
    }
    & .Mui-disabled {
      color: #ff6666;
    }
    &:hover fieldset {
      border-color: #50c2a9;
      border-width: 2px;
    }
    &.Mui-focused fieldset {
      border-color: #50c2a9;
    }
  }
`;

export const StyledTooltip = styled((props) => {
  return (
    <Tooltip
      enterTouchDelay={0}
      classes={{ popper: props.className }}
      {...props}
    />
  );
})`
  .MuiTooltip-tooltip {
    font-size:${({ fontSize }) => (fontSize ? fontSize : 'initial')};
    color:${({ $color }) => ($color ? $color : 'initial')};
    display: flex;
    background-color: white;
    padding:${({ padding }) => (padding ? padding : '12px')};
    border-radius: 2px;
    box-shadow: 0px 0px 24px #00000034;
  }
  .MuiTooltip-arrow {
    &:before {
      background-color: white;
    }
`;
