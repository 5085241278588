import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const SaturdayDrivingCheckBox = ({
  checked,
  handleSaturdayDrivingChecked,
  direction,
}) => {
  const { t } = useTranslation();
  return (
    <FormGroup row>
      <FormControlLabel
        sx={{
          marginRight: direction === 'rtl' && '0px',
          marginLeft: direction === 'ltr' && '0px',
        }}
        control={
          <Checkbox
            checked={checked}
            onChange={(e) => handleSaturdayDrivingChecked(e.target.checked)}
          />
        }
        label={t('picker.carpicker.saturdayDrive')}
      />
    </FormGroup>
  );
};

export default SaturdayDrivingCheckBox;
