import { Fade, FormControl, FormHelperText } from '@mui/material';
import React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GreenBorderTextField } from '../../../../ReusableComponents/StyledComponents';
import { AgeErrorTooltip } from '../styles/AgeSelection.styled';

const ConditionalWrapper = ({
  condition,
  open,
  title,
  children,
  error,
  helperText,
}) => {
  return condition ? (
    <AgeErrorTooltip
      arrow={true}
      open={open}
      title={title}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 1000 }}>
      {children}
    </AgeErrorTooltip>
  ) : (
    <>
      {children}
      <FormHelperText style={{ color: '#f44336' }}>
        {error && helperText}
      </FormHelperText>
    </>
  );
};

const AgeSelection = ({
  isDesktop,
  driverAge,
  handleAgeChange,
  helperText,
  error,
  direction,
}) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (error !== undefined && helperText !== '' && helperText !== undefined) {
      setOpen(true);
    } else setOpen(false);
  }, [error, helperText]);

  return (
    <FormControl
      variant="outlined"
      fullWidth
      style={{ width: isDesktop ? '70px' : '100%' }}>
      <ConditionalWrapper
        condition={isDesktop}
        title={helperText || ''}
        open={open}
        error={error}
        helperText={helperText}>
        <GreenBorderTextField
          $direction={direction}
          InputProps={{
            style: { fontWeight: 500, height: isDesktop ? 25 : 'auto' },
          }}
          variant="outlined"
          size={isDesktop ? 'small' : 'medium'}
          value={driverAge}
          label={isDesktop ? '' : t('picker.carpicker.driverAge.driverAge')}
          type="number"
          error={error}
          onInput={(e) => {
            e.target.value = Math.max(0, parseInt(e.target.value, 10))
              .toString()
              .slice(0, 2);
          }}
          onChange={(e) =>
            handleAgeChange('driverAge', parseInt(e.target.value))
          }
        />
      </ConditionalWrapper>
    </FormControl>
  );
};

export default AgeSelection;
