import styled from 'styled-components';

export const StyledSelect = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled.img`
  margin-right: 6px;
  height: 20px;
  width: 20px;
`;

export const LocationTitle = styled.div`
  /* width: 100%; */
  font-weight: 500;
`;

export const Country = styled.div`
  text-align: ${({ $direction }) => ($direction === 'rtl' ? 'right' : 'left')};
  font-size: 12px;
`;
