import Popover from '@mui/material/Popover';
import styled from 'styled-components';
import { DateRange } from 'react-date-range';
import { Autocomplete } from '@mui/lab';
import { GreenBorderTextField } from '../../../../../../ReusableComponents/StyledComponents';
import { css } from 'styled-components';

const startDate = css`
  border-top-left-radius: 1.042em;
  border-bottom-left-radius: 1.042em;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
`;
const endDate = css`
  border-top-right-radius: 1.042em;
  border-bottom-right-radius: 1.042em;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
`;

export const StyledPopover = styled(Popover)`
  .MuiPopover-paper {
    width: ${({ $isMobile }) => ($isMobile ? '100%' : 'auto')};
  }
`;

export const StyledDateRange = styled(DateRange)`
  width: 100%;
  * {
    font-size: 0.875rem;
  }
  // TODO HOVERED DAY DIRECTION
  .rdrDayHovered {
    &.rdrDayStartPreview {
      border-radius: 1.042em;
    }
  }
  .rdrMonth {
    width: ${({ $isMobile }) => ($isMobile ? 'auto' : '27.667em')};
  }

  .rdrMonthName {
    color: black;
  }
  .rdrDayNumber,
  .rdrDayToday {
    font-weight: 400;
  }
  /* .rdrDayToday {
    font-size: 0.875rem;
  } */
  .rdrMonthAndYearWrapper {
    display: ${({ set }) => (set ? 'none' : 'flex')};
  }
  .rdrMonths {
    &.rdrMonthsHorizontal {
      display: ${({ set }) => (set ? 'none' : 'flex')};
    }
  }
  .rdrStartEdge {
    ${({ $direction }) => ($direction === 'ltr' ? startDate : endDate)}
    &.rdrEndEdge {
      ${({ $direction }) => ($direction === 'ltr' ? startDate : endDate)}
    }
  }
  .rdrEndEdge {
    ${({ $direction }) => ($direction === 'ltr' ? endDate : startDate)}
  }
  .rdrDayStartPreview {
    ${({ $direction }) => ($direction === 'ltr' ? startDate : endDate)}
  }
  .rdrDayEndPreview {
    ${({ $direction }) => ($direction === 'ltr' ? endDate : startDate)}
  }
`;

export const StyledDateTextField = styled(GreenBorderTextField)`
  .MuiInputBase-root {
    border-radius: 2px 0px 0px 2px;
  }
`;
export const StyledTimePicker = styled(Autocomplete)`
  .MuiInputBase-root {
    border-radius: 0px 2px 2px 0px;
  }
  .MuiOutlinedInput-input {
    font-weight: 500;
  }
`;
