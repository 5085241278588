import React from 'react';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import TrainIcon from '@mui/icons-material/Train';
import deliveryIcon from '../../../icons/delivery2.svg';
import portIcon from '../../../icons/port.svg';
import { Icon, StyledSelect } from './styles/Option.styled';
import { getCountryFlagByCode } from '../../../../../../../../data/countries';
import OptionName from './components/OptionName';

const LocationTypeIcon = ({ LocationType, direction }) => {
  const styles = {
    icon: {
      margin: `0 ${direction === 'rtl' ? 0 : '6px'} 0 ${direction === 'rtl' ? '6px' : 0}`
    }
  };
  switch (LocationType) {
    case 'city':
      return <LocationCityIcon style={styles.icon} />;
    case 'airport':
      return <LocalAirportIcon style={styles.icon} />;
    case 'railway':
      return <TrainIcon style={styles.icon} />;
    case 'port':
      return <Icon src={portIcon} />;
    case 'delivery':
      return <Icon src={deliveryIcon} />;
    default:
      return <></>;
  }
};

const Option = ({ LocationType, LocationName, country, code, direction }) => {
  const flag = getCountryFlagByCode(code);
  return (
    <StyledSelect  style={{ width: '100%' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <LocationTypeIcon LocationType={LocationType} direction={direction} />
        {flag && (
          <img
            src={flag}
            alt={code}
            width={27}
            height={27}
            style={{ margin: `0 ${direction === 'rtl' ? 0 : '6px'} 0 ${direction === 'rtl' ? '6px' : 0}` }}
          />
        )}
        <OptionName name={LocationName} country={country} direction={direction} />
      </div>
    </StyledSelect>
  );
};

export default Option;
