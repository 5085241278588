import { Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';

const DifferentLocationCheckBox = ({
  isDifferentLocation,
  pickUpLocationCode,
  dropOffLocationCode,
  handleDifferentLocation,
  t,
}) => {
  return (
    <FormControlLabel
      sx={{ marginRight: 0 }}
      control={
        <Checkbox
          checked={
            isDifferentLocation || pickUpLocationCode !== dropOffLocationCode
          }
          onChange={handleDifferentLocation}
          color="secondary"
        />
      }
      label={t('picker.carpicker.dropOffDifferentLocation')}
    />
  );
};

export default DifferentLocationCheckBox;
