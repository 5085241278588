import React, { useState, useContext } from 'react';
import {
  Paper,
  ClickAwayListener,
  MenuList,
  Button,
  Popper,
  MenuItem,
  Fade,
  Typography,
} from '@mui/material';
import { indigo } from '@mui/material/colors';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEuroSign,
  faDollarSign,
  faRubleSign,
  faShekelSign,
} from '@fortawesome/free-solid-svg-icons';
import LanguageIcon from '@mui/icons-material/Language';
import { CurrencyContext } from '../../../../SharedState';
import { useTranslation } from 'react-i18next';
import AgentMenuItem from './MenuItems/AgentMenuItem/AgentMenuItem';
import Flag from './Flag';
import { changeURLByLanguage } from './utils';

const NavMenuItem = ({ navItemText, link, subMenu, type, role, ariaLabel }) => {
  const {
    i18n: {
      languages: [lng],
    },
  } = useTranslation();
  const [open, setOpen] = useState(false);
  const [agentOpen, setAgentOpen] = useState(false);
  const currencyIcons = [
    { icon: faDollarSign, sign: '$', code: 'USD' },
    { icon: faEuroSign, sign: '€', code: 'EUR' },
    { icon: faRubleSign, sign: '₽', code: 'RUB' },
    { icon: faShekelSign, sign: '₪', code: 'ILS' },
  ];

  const [currency, setCurrency] = useContext(CurrencyContext);

  const [language, setLanguage] = useState(
    <div
      style={{ display: 'flex' }}
      aria-label="Change language"
      role="menuitem">
      <div style={{ marginRight: 8 }}>{lng.toUpperCase()}</div>
      <LanguageIcon />
    </div>
  );

  const anchorRef = React.useRef(null);
  const languageRef = React.useRef(null);
  const handleToggle = (name) => {
    if (name === 'agent') {
      setAgentOpen((prevOpen) => !prevOpen);
    } else if (name === 'language') {
      setOpen((prevOpen) => !prevOpen);
    }
  };

  const handleClose = (event, type, item) => {
    const selectedCurrency = currencyIcons[subMenu.indexOf(item)];

    switch (type) {
      case 'currency':
        setCurrency({
          icon: (
            <FontAwesomeIcon
              style={{ fontSize: '20px' }}
              icon={selectedCurrency.icon}
            />
          ),
          sign: selectedCurrency.sign,
          code: selectedCurrency.code,
        });
        break;
      case 'language':
        window.location.replace(changeURLByLanguage(item.code));

        // setLanguage(<Flag item={item} />); //TODO refresh page
        break;
      default:
        break;
    }

    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  return (
    <>
      <Button
        role={role}
        aria-label={ariaLabel}
        color="inherit"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        name={type}
        onClick={(e) => {
          handleToggle(e.currentTarget.name);
        }}>
        {type === 'currency' || type === 'language'
          ? type === 'currency'
            ? currency
            : language
          : navItemText}

        {/* {subMenu !== undefined ? <FontAwesomeIcon style={{marginLeft: '5px'}} icon={faChevronDown} /> : null} */}
      </Button>
      {type === 'agent' && (
        <AgentMenuItem
          anchorRef={anchorRef}
          handleListKeyDown={handleListKeyDown}
          open={agentOpen}
          setOpen={setAgentOpen}
        />
      )}
      {subMenu !== undefined ? (
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          transition
          disablePortal
          placement="bottom-end">
          {({ TransitionProps, placement }) => (
            <Fade
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}>
                    {subMenu.map((item, idx) => {
                      return (
                        <MenuItem
                          sx={{
                            '&:hover': {
                              color: indigo[500],
                            },
                          }}
                          key={item.code}
                          onClick={(e) => handleClose(e, type, item)}>
                          {type === 'language' ? (
                            <>
                              <Flag language={item.code} />
                              <Typography
                                variant="body2"
                                sx={{
                                  fontWeight: 600,
                                  marginLeft: '8px',
                                }}>
                                {item.lng}
                              </Typography>
                            </>
                          ) : (
                            { item }
                          )}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </Popper>
      ) : null}
    </>
  );
};

export default NavMenuItem;
