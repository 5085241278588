import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  displayPriceWithCurrency,
  nameMapping,
} from '../../../../../../../utils';

export default function CustomRow({ name, value, currency }) {
  const { t } = useTranslation();
  return (
    <TableRow>
      <TableCell component="th" scope="row" align="right">
        {nameMapping(name, t)}{' '}
      </TableCell>
      <TableCell align="right">
        {displayPriceWithCurrency('', value.toFixed(2), currency)}
      </TableCell>
    </TableRow>
  );
}
