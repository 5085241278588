export const changeURLByLanguage = (lng) => {
  const { location } = window;
  const { protocol, pathname, host, search } = location;
  const pn = pathname.split('/');
  pn[1] = lng;
  if (pn[2] === 'search') {
    pn[3] = 'cc';
  }
  return protocol + '//' + host + pn.join('/') + search;
};
