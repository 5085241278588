import { InputAdornment } from '@mui/material';

const getAdornment = (direction, formik) => {
  if (direction === 'ltr')
    return {
      startAdornment: (
        <InputAdornment position={'start'}>
          <>{formik.values.phoneCode.flag} +</>
        </InputAdornment>
      ),
    };
  else
    return {
      endAdornment: (
        <InputAdornment position={'end'}>
          <>+ {formik.values.phoneCode.flag} </>
        </InputAdornment>
      ),
    };
};
export default getAdornment;
