import { Collapse, TextField } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonWithLoader } from '../../../../ReusableComponents/ButtonWithLoader';
import AgeSelection from '../Components/AgeSelection';
import { SearchButton } from '../Components/CarDatePicker/styles/styledComponents';
import CarSelection from '../Components/CarSelection/CarSelection';
import DatePicker from '../Components/DatePicker/DatePicker';
import SearchLocation from '../Components/SearchLocation/SearchLocation';
import DifferentLocationCheckBox from '../Components/DifferentLocationCheckBox/DifferentLocationCheckBox';
import SaturdayDrivingCheckBox from '../Components/CheckBoxes/SaturdayDrivingCheckBox';
import { useTheme } from '@mui/styles';

const MobilePicker = ({
  getDatePickerProps,
  formik,
  locationProps,
  isDifferentLocation,
  handleDifferentLocation,
  saturdayDriveDialogOpen,
  handleClose,
  open,
  handleSaturdayDrivingChecked,
  size,
  handleLandingCarSelected,
  orderLoading,
  isLoading,
  matches,
  sizeBetweenLGXL,
  handleSaturdayDrivingCloseDialog,
}) => {
  const {
    t,
    i18n: {
      languages: [lng],
    },
  } = useTranslation();
  const { direction } = useTheme();
  // const Item = styled(Paper)(({ theme }) => ({
  //   backgroundColor: '#fff',
  //   ...theme.typography.body2,
  //   padding: theme.spacing(1),
  //   textAlign: 'center',
  //   color: theme.palette.text.secondary,
  //   ...theme.applyStyles('dark', {
  //     backgroundColor: '#1A2027'
  //   })
  // }));
  return (
    <Grid container spacing={1} direction="column">
      <Grid size={{ xs: 12 }} style={{ marginTop: 16 }}>
        <SearchLocation
          {...locationProps}
          isMobile={true}
          isPickupDate={true}
          location={formik.values.pickUpLocation}
          error={
            formik.touched.pickUpLocation &&
            Boolean(formik.errors.pickUpLocation)
          }
          helperText={
            formik.touched.pickUpLocation && formik.errors.pickUpLocation
          }
        />
      </Grid>
      <Grid size={{ xs: 12 }}>
        <DifferentLocationCheckBox
          handleDifferentLocation={handleDifferentLocation}
          isDifferentLocation={isDifferentLocation}
          pickUpLocationCode={formik.values.pickUpLocation.LocationCode}
          dropOffLocationCode={formik.values.dropOffLocation.LocationCode}
          t={t}
        />
      </Grid>
      <Grid size={{ xs: 12 }} style={{ marginTop: 8 }}>
        <Collapse
          in={
            isDifferentLocation ||
            formik.values.dropOffLocation.LocationCode !==
              formik.values.pickUpLocation.LocationCode
          }>
          <SearchLocation
            {...locationProps}
            isPickupDate={false}
            location={formik.values.dropOffLocation}
            isMobile={true}
          />
        </Collapse>
      </Grid>

      <Grid size={{ xs: 12 }}>
        <DatePicker {...getDatePickerProps(true)} />
        {/* <CarDatePicker {...getDatePickerProps(true)} handlePickUpVisited={handlePickUpVisited} /> */}
      </Grid>
      <Grid size={{ xs: 12 }}>
        <DatePicker
          {...getDatePickerProps(false)}
          pickUpTime={formik.values.pickUpTime}
        />
      </Grid>

      {/**Number of days section coupon and age */}
      {/* <Grid size={{ xs: 12 }}>
        <Grid container spacing={1} style={{ border: '1px solid' }} direction='row'>
          <Grid size={{ xs: 4 }} style={{ border: '1px solid' }}>
            <TextField
              // fullWidth
              label={t('picker.carpicker.couponCode')}
              variant='outlined'
            />
          </Grid>

          <Grid size={{ xs: 5 }} style={{ padding: '4px', border: '1px solid' }}>
            <AgeSelection
              size={size}
              error={formik.touched.driverAge && Boolean(formik.errors.driverAge)}
              helperText={formik.errors.driverAge}
              driverAge={formik.values.driverAge}
              handleAgeChange={formik.setFieldValue}
              lng={lng}
            />
          </Grid>
        </Grid>
      </Grid> */}
      <Grid container spacing={1} justifyItems="center">
        <Grid
          size={{ xs: 7 }}
          style={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            // fullWidth
            label={t('picker.carpicker.couponCode')}
            variant="outlined"
          />
        </Grid>

        <Grid size={{ xs: 5 }} style={{ padding: '4px' }}>
          <AgeSelection
            direction={direction}
            size={size}
            error={formik.touched.driverAge && Boolean(formik.errors.driverAge)}
            helperText={formik.errors.driverAge}
            driverAge={formik.values.driverAge}
            handleAgeChange={formik.setFieldValue}
            lng={lng}
          />
        </Grid>
      </Grid>
      <Suspense fallback={<div></div>}>
        <Grid size={{ xs: 12 }}>
          <Collapse
            in={
              !formik.values.country.isLanding
                ? false
                : formik.values.country.isLanding
            }>
            {formik.values.country.isLanding && (
              <CarSelection
                t={t}
                handleLandingCarSelected={handleLandingCarSelected}
                selectedCar={formik.values.landingSelectedCar}
                size={size}
                country={formik.values.country}
              />
            )}{' '}
          </Collapse>
        </Grid>
      </Suspense>

      <Grid container justifyContent="space-between">
        <Collapse in={formik.values.country.code === 'il'}>
          <Grid size={{ xs: 12 }}>
            <SaturdayDrivingCheckBox
              checked={!formik.values.drivingOnSaturday}
              onChange={handleSaturdayDrivingChecked}
            />
          </Grid>
        </Collapse>

        <Grid size={{ xs: 12, sm: 4 }} style={{ display: 'flex' }}>
          {formik.values.country.isLanding ? (
            <ButtonWithLoader
              isLoading={orderLoading}
              name={t('common.orderShort')}
              onClickHandler={formik.handleSubmit}
              buttonColor="secondary"
              buttonVariant="contained"
            />
          ) : (
            <SearchButton
              disabled={isLoading}
              ispc={matches.valueOf.toString()}
              variant="contained"
              color="secondary"
              onClick={formik.handleSubmit}>
              {t('picker.carpicker.searchButton')}
            </SearchButton>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MobilePicker;
